import React from 'react';
import style from '../LandingFoqus.module.css'
import curiosity from '../../../images/values/curiosity.svg'
import data_driven from '../../../images/values/data_driven.svg'
import empathy from '../../../images/values/empathy.svg'
import integrity from '../../../images/values/integrity.svg'
import synergetic from '../../../images/values/synergetic.svg'
import values_mobile_splat_1 from '../../../images/newLangingSplats/values_mobile_splat_1.svg'

function Values() {
	const topItems = [
		{
			title: 'Integrity',
			icon: integrity,
			desc: 'We see everything holistically to approach with honest, ethical, consistent actions, values, and principles, even when no one is watching',
		},
		{
			title: 'Empathy',
			icon: empathy,
			desc: 'We understand and share the feelings of others, fostering a supportive and collaborative environment through human-centric approach',
		},
	]

	const bottomItems = [
		{
			title: 'Synergetic',
			icon: synergetic,
			desc: 'We work in one unified flow state, so that the combined effort produces outcomes greater than what individuals could achieve alone',
		},
		{
			title: 'Data Driven',
			icon: data_driven,
			desc: 'We make decisions and take actions based on objective data by science backed methods, rather than intuition or assumptions',
		},
		{
			title: 'Curiosity',
			icon: curiosity,
			desc: 'We desire to explore, learn, and understand  new id eas, concepts, or experiences with an open and questioning mindset',
		},
	]

	return (
		<div className={style.values_wrapper}>
			<div className={style.container}>
				<div className={style.values_title}>
					OUR CORE VALUES
				</div>
				<div className={style.values_top_wrapper}>
					{topItems.map((item,index) => <ValueCard key={`value_${index}`} {...item}/>)}
				</div>
				<div className={style.values_bottom_wrapper}>
					{bottomItems.map((item,index) => <ValueCard key={`value_${index}`} {...item}/>)}
				</div>
			</div>
			<img src={values_mobile_splat_1} id={'about_us_mobile_splat_1'}/>
		</div>
	);
}

function ValueCard(props){
	const { title, icon, desc } = props
	return <div className={style.value_card}>
		<div className={style.value_card_title}>
			<img src={icon} className={style.value_card_img}/>
			{title}
		</div>
		<div className={style.value_card_desc}>{desc}</div>
	</div>
}

export default Values;
