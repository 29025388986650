import React from 'react';
import style from '../LandingFoqus.module.css'
import about_us_bg from '../../../images/aboutUsPromo/about_us_bg.svg'
import about_us_bg_small from '../../../images/aboutUsPromo/about_us_bg_small.svg'
import logo from "../../../images/Foqus_white.png";
import {MenuOutlined} from "@ant-design/icons";
import {useUnit} from "effector-react";
import {toggleShowMenuEv} from "../../../models/newLandingModel";

function AboutUsPromo({setTab, isPhone}) {
	const toggleShowMenu = useUnit(toggleShowMenuEv)

	return (
		<div className={style.about_us_promo_wrapper}>
			<div className={style.container}>
				<div className={style.promo_header_wrapper}>
					<img src={logo} className={style.promo_logo} onClick={() => setTab('/')}/>
					<MenuOutlined className={style.promo_hamburger} onClick={toggleShowMenu}/>
				</div>
				<div className={style.about_us_promo_info}>
					<div className={style.about_us_promo_title}>
						GET TO KNOW FOQUS
					</div>
					<div className={style.about_us_promo_desc}>
						We are transforming society<br/>
						for a smarter, safer and more engaged future<br/>
						by democratizing Behavioral Intelligence across industries. Partner with us to gain a strategic edge<br/>
						and be part of this groundbreaking journey.
					</div>
				</div>
			</div>
			<img src={isPhone ? about_us_bg_small : about_us_bg} className={style.about_us_promo_bg}/>
		</div>
	);
}

export default AboutUsPromo;
