import React from 'react';
import style from '../LandingFoqus.module.css'
import about_us_splat_2 from '../../../images/newLangingSplats/about_us_splat_2.svg'

function Mission() {
	return (
		<div className={style.mission_wrapper}>
			<div className={style.container}>
				<div className={style.mission_title}>
					Our mission
				</div>
				<div className={style.mission_desc}>
					We empower new dimensions<br/>
					of behavioral data analytics<br/>
					and strive to uncover hidden<br/>growth opportunities for insightful<br/>decision-making
				</div>
			</div>
			<img src={about_us_splat_2} id={'about_us_splat_2'}/>
		</div>
	);
}

export default Mission;
