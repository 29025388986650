import { createBrowserRouter } from 'react-router-dom';
import App from '../App.js';
import Registration from "../pages/Registration/Registration";
import Landing from '../pages/Main/Landing';
import TermsAndConditions from '../pages/Registration/Terms';
import HrEvent from '../pages/HrEvent/HrEvent';
import OldLandingFoqus from '../pages/OldLandingFoqus/OldLandingFoqus';
import Presentation from '../pages/Presentation/Presentation';
import DownloadPDF from '../pages/Presentation/Presentation';
import LandingFoqus from "../pages/LandingFoqus/LandingFoqus";
import Onboarding from "../pages/Onboarding/Onboarding";

const landingRoutes = ['/', '/blog', '/blog/:id', '/about-us']

const routes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      ...landingRoutes.map(item => {
        return {
          path: item,
          element: <LandingFoqus />
        }
      }),
      { path: '/main', element: <Landing /> },
      { path: '/creatti', element: <Registration />},
      { path: '/terms', element: <TermsAndConditions/>},
      { path: '/hr-event', element: <HrEvent />},
      // { path: '/landing', element: <OldLandingFoqus />},
      { path: '/onboarding', element: <Onboarding />},
      { path: '/landing', element: <OldLandingFoqus />},
      { path: '/presentation', element: <DownloadPDF />},
      { path: '/hr-tech', element: <Landing /> },
      // { path: '/new-landing', element: <LandingFoqus /> },
    ],
  },
]);

export default routes;
