import React from 'react';
import style from '../LandingFoqus.module.css'
import beliefs_stars from '../../../images/beliefs/beliefs_stars.svg'

function Beliefs() {
	return (
		<div className={style.beliefs_wrapper}>
			<div className={style.container}>
				<div className={style.beliefs_info}>
					<div>
						<div className={style.beliefs_title}>Our beliefs</div>
						<div className={style.beliefs_desc}>
							We believe that by helping people understand their behavior,
							we create a better world.
							Fewer conflicts, better relationships, more innovation, improved leadership
						</div>
					</div>
					<img src={beliefs_stars} className={style.beliefs_stars}/>
				</div>
			</div>
		</div>
	);
}

export default Beliefs;
