import React from 'react';
import style from '../LandingFoqus.module.css'
import Aigerim from '../../../images/team/Aigerim.png'
import Almaz from '../../../images/team/Almaz.png'
import Bakytzhan from '../../../images/team/Bakytzhan.png'
import Dimash from '../../../images/team/Dimash.png'
import Islambek from '../../../images/team/Islam-bek.png'
import Serik from '../../../images/team/Serik.png'
import Taya from '../../../images/team/Taya.png'
import Yusup from '../../../images/team/Yusup.png'
import about_us_splat_1 from '../../../images/newLangingSplats/about_us_splat_1.svg'

function Team() {
	const members = [
		{
			name: 'Serik',
			photo: Serik,
			position: 'Solution Architect'
		},
		{
			name: 'Almaz',
			photo: Almaz,
			position: 'Cloud Engineer'
		},
		{
			name: 'Islam-bek',
			photo: Islambek,
			position: 'MLOps'
		},
		{
			name: 'Dimash',
			photo: Dimash,
			position: 'Edge Computing Engineer'
		},
		{
			name: 'Bakytzhan',
			photo: Bakytzhan,
			position: 'ML Engineer'
		},
		{
			name: 'Aigerim',
			photo: Aigerim,
			position: 'FullStack Developer/\nProgrammer/Engineer'
		},
		{
			name: 'Yusup',
			photo: Yusup,
			position: 'Business Development'
		},
		{
			name: 'Taya',
			photo: Taya,
			position: 'UX Engineer'
		},
	]

	return (
		<div className={style.team_wrapper}>
			<div className={style.container}>
				<div className={style.team_title}>
					Our team of experts
				</div>
				<div className={style.team_cards_wrapper}>
					{
						members.map((member,index) => {
							return <div className={style.team_card} key={`member_${index}`}>
								<img src={member.photo}/>
								<div className={style.team_card_name}>{member.name}</div>
								<div className={style.team_card_position}>{member.position}</div>
							</div>
						})
					}
				</div>
			</div>
			<img src={about_us_splat_1} id={'about_us_splat_1'}/>
		</div>
	);
}

export default Team;
