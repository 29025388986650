import style from './LandingFoqus.module.css'
import Header from "./sections/Header";
import Business from "./sections/Business";
import Advantages from "./sections/Advantages";
import TrustedBy from "./sections/TrustedBy";
import HowItWorks from "./sections/HowItWorks";
import Promo from "./sections/Promo";
import CaseStudy from "./sections/CaseStudy";
import RequestDemo from "./sections/RequestDemo";
import Footer from "./sections/Footer";
import ResponsePopup from "./sections/ResponsePopup";
import {useMemo, useState} from "react";
import BlogPromo from "./blogSections/BlogPromo";
import BlogPosts from "./blogSections/BlogPosts";
import {useLocation, useNavigate} from "react-router-dom";
import PostPage from "./blogSections/PostPage";
import FAQ from "./sections/FAQ";
import MainPosts from "./sections/MainPosts";
import AboutUsPromo from "./aboutUsSections/AboutUsPromo";
import Values from "./aboutUsSections/Values";
import Beliefs from "./aboutUsSections/Beliefs";
import Team from "./aboutUsSections/Team";
import Mission from "./aboutUsSections/Mission";
export default function LandingFoqus(){
	const isPhone = window.outerWidth < 1200
	const {pathname} = useLocation()
	const navigate = useNavigate()
	const setTab = (route) => {
		navigate(route)
		window.scrollTo(0, 0);
	}

	const content = useMemo(() => {
		switch (pathname){
			case '/': return <>
				<Promo
					setTab={setTab}
				/>
				<Business isPhone={isPhone}/>
				<Advantages isPhone={isPhone}/>
				<HowItWorks/>
				<CaseStudy isPhone={isPhone}/>
				<FAQ />
				<TrustedBy/>
				<RequestDemo isPhone={isPhone}/>
				<MainPosts />
				<ResponsePopup />
				{/*{*/}
				{/*	!isPhone && <img src={dots_bg} className={style.dots_bg}/>*/}
				{/*}*/}
			</>
			case '/blog': return <>
				<BlogPromo setTab={setTab}/>
				<BlogPosts isPhone={isPhone}/>
			</>
			case pathname.match(/\/blog\/\d{1}/)?.input: return <PostPage isPhone={isPhone} setTab={setTab}/>
			case '/about-us': return <>
				<AboutUsPromo setTab={setTab} isPhone={isPhone}/>
				<Values />
				<Beliefs />
				<Team />
				<Mission />
			</>
			default: return <>
				<Promo/>
				<Business/>
				<Advantages isPhone={isPhone}/>
				<TrustedBy/>
				<CaseStudy isPhone={isPhone}/>
				<HowItWorks/>
				<RequestDemo isPhone={isPhone}/>
				<ResponsePopup />
				{/*{*/}
				{/*	!isPhone && <img src={dots_bg} className={style.dots_bg}/>*/}
				{/*}*/}
			</>
		}
	}, [pathname])

	return <div className={style.landingfoqus_wrapper}>
		<Header
			setTab={setTab}
			isPhone={isPhone}
		/>
		{content}
		<Footer isPhone={isPhone} setTab={setTab}/>
	</div>
}
